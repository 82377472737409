import { useQuery } from "@tanstack/react-query";
import ArticleClient from "@/client/ArticleClient";

const articleClient = new ArticleClient();

export const getLatestArticles = (limit?: number) =>
  useQuery({
    queryKey: ["getLatestArticles"],
    queryFn: () => articleClient.getArticles(limit),
  });

export const getStepForwardArticles = () =>
  useQuery({
    queryKey: ["getStepForwardArticles"],
    queryFn: () =>
      articleClient.getArticlesByIds([
        "1UZYtbCneLO38SsSLB1qR1",
        "5XbA4QPyGpzUfVlR3haK4B",
        "5eEPJwTtylvENLwyS6kRHA",
      ]),
  });
